@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100%;
  height: auto;
  background-image: linear-gradient(180deg,
      #386ef6,
      #448af5 49%,
      rgba(83, 164, 243, 0)) !important;
}

.gradientColor {
  background-image: linear-gradient(180deg,
      #386ef6,
      #448af5 49%,
      rgba(83, 164, 243, 0)) !important;
}

.transactionSectionGradient {
  background: linear-gradient(90deg, #fd9a5c, #fbe6b7) !important;
}

.swapCardGradient {
  background: linear-gradient(90deg, #fdd75c, #fbe6b7) !important;
}

/* table global classes  */
.rdt_TableHead {
  height: 60px !important;
}

.rdt_TableRow {
  height: 92px !important;
}

.rdt_TableCol_Sortable {
  div {
    font-size: 28px !important;
    color: #3e536c !important;
  }
}

.rdt_TableCol:nth-child(3) {
  justify-content: end !important;
}

.rdt_TableCell:nth-child(3) {
  justify-content: end !important;
}

.rdt_TableCell {
  div {
    font-size: 28px !important;
    color: #8995a4 !important;
  }
}

.helpPageOnAnimation {
  animation: animate 0.3s ease-in-out !important;
}

@keyframes animate {
  0% {
    right: -100%;
  }

  100% {
    right: 0;
  }
}

.helpPageOffAnimation {
  animation: animateOff 0.3s ease-in-out !important;
}

@keyframes animateOff {
  0% {
    right: 0;
  }

  100% {
    right: -100%;
  }
}

.clipBoardTextAnimation {
  animation: textAnimate 0.5s ease-in-out;
}

@keyframes textAnimate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}